<template>
  <div class="hello">
    <div class="row container">
      <div class="col-md-6">
        <div class="mb-3" style="text-align: left;">
          <label for="exampleFormControlInput1" class="form-label">lat</label>
          <input v-model="lat" type="number" class="form-control" id="exampleFormControlInput1">
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3" style="text-align: left;">
          <label for="exampleFormControlInput1" class="form-label">lng</label>
          <input v-model="lng" type="number" class="form-control" id="exampleFormControlInput1">
        </div>
      </div>
      <div class="col-md-12" style="text-align:left">
        <button type="submit" class="btn btn-primary" @click="search()">Search</button>
      </div>

    </div>
    <div :style="size" id="map"></div>
  </div>
</template>

<script>
import "@maptiler/sdk/dist/maptiler-sdk.css";
import * as maptilersdk from '@maptiler/sdk';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      lat: null,
      lng: null,
      // lat:19.0281697,
      // lng:99.8940683,
      size:0
      
    }
  },
  mounted() {
    console.log(window.innerHeight/10);
    this.size = 
'margin-top:'+window.innerHeight/10*4+'px'
    maptilersdk.config.apiKey = '2pWrecVYhRzVG7Vj7wEJ';
    if (this.lat && this.lng) {
      this.getmap()
    }
  },
  methods: {
    search() {
      if (this.lat && this.lng) {
        this.getmap()
      } else {
        alert('กรุณากรอกค่า lat และ lng ให้ครบถ้วน')
      }
    },
    getmap() {
      const map = new maptilersdk.Map({
        container: 'map', // container's id or the HTML element to render the map
        style: maptilersdk.MapStyle.OUTDOOR,
        // center:[6.85684, 45.83595],
        center: [this.lng, this.lat], // starting position [lng, lat]
        zoom: 14, // starting zoom
        terrain: true,
        terrainControl: true,
        pitch: 61,
        bearing: 172,
        maxPitch: 85,
        // maxZoom: 14
      });
      document.getElementById("map").value = map
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> body {
   margin: 0;
   padding: 0;
 }

 #map {
   position: absolute;
   top: 25px;
   bottom: 0;
   width: 100%;
 }</style>
